import React from "react"
import Topic from "../../components/Topic"

const Finance = () => (
  <Topic id="finance">
    <p>
      As a newcomer to Canada, it is important to understand the Canadian
      financial system, the services that Canadian banks offer, the different
      types of payment methods as well as the various sales taxes you pay when
      making purchases. Since the cost of living is quite high in Canada, it is
      useful to know how to do grocery shopping while on a tight budget. This
      section also includes advice on how to protect against fraud and other
      financial risks.
    </p>
    <p>
      Cellular plans are expensive in Canada but a necessity. Newcomers need to
      compare various companies before selecting a cell phone and / or data plan
      for your residence. Canada offers some financial benefits and credits to
      newcomers even when they have just arrived with no income. After the
      application is approved, in order to continue to receive the benefit and
      credit payments, everyone, including newcomers, must file a tax return
      every year regardless of whether you have any income or not.
    </p>
    <p>
      When you start working, it is also a requirement to pay income tax. The
      deadline for filing tax returns is typically on April 30 of each year.
      There are free tax clinics in the community that can help newcomers with
      the filing. Click on File Income Tax or watch the videos to learn more
      about how to file a tax return or what to do if Canada Revenue Agency
      (CRA) asks you for more information.
    </p>
  </Topic>
)

export default Finance
